import { START_LOADING, STOP_LOADING } from './mutation-types';

export default {
  [START_LOADING](state, action) {
    state.loadingStates[action] = true;
  },

  [STOP_LOADING](state, action) {
    state.loadingStates[action] = false;
  },
};

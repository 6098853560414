import isValidDomain from 'is-valid-domain';

import { CRON_END_TIME_MINUTES } from '~/support/constants';
import { cron as cronUtil, str } from '~/support/utils';

export const alphaWithUnderscores = (value) => {
  const pattern = /^([a-zA-Z0-9_]*)$/;

  return pattern.test(value);
};

export const alphaDashWithSpace = (value) => {
  const pattern = /^([a-zA-Z0-9_ -]*)$/;

  return pattern.test(value);
};

export const ascii = (value, { max }) => {
  // replace those characters with their real values in order to not have double length
  const characters = {
    '\\n': '\n',
    '\\r': '\r',
    '\\t': '\t',
  };
  Object.keys(characters).forEach((key) => (value = value.replaceAll(key, characters[key])));

  return value.length <= max && str.isAscii(value);
};

export const cron = (value) => {
  return cronUtil.isValid(value);
};

export const cronInterval = (value, { startTime, endTime }) => {
  if (!endTime) return true;

  const startTimeMinutes = startTime * 60;
  const endTimeMinutes = endTime * CRON_END_TIME_MINUTES;
  const isValidInterval = endTimeMinutes - startTimeMinutes >= value;

  return isValidInterval;
};

export const cronEndTime = (value, { startTime }) => {
  return value > startTime;
};

export const dateRangeStartRequired = ({ start }) => {
  return !!start;
};

export const emailDomains = (values) => {
  return Array.isArray(values) ? values.every((value) => isValidDomain(value)) : isValidDomain(values);
};

export const firstCharacterNotNumber = (value) => {
  const pattern = /^[^0-9]/;

  return pattern.test(value);
};

export const fullName = (value) => {
  const pattern = /(\w.+\s).+/;

  return pattern.test(value);
};

export const host = (value) => {
  const pattern =
    /^((([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])\.)(([a-zA-Z0-9]*[a-zA-Z])\.)*([a-zA-Z0-9]*[a-zA-Z]))|^([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])$/;

  return pattern.test(value);
};

export const hostOrIp = (value) => {
  return host(value) || ip(value);
};

export const ip = (value) => {
  const pattern =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  return pattern.test(value);
};

export const oneChildFieldRequired = (value, { children }) => {
  if (!value) return true;

  return children.some((child) => !!child);
};

export const oneFieldRequired = (value) => {
  return !!value.length;
};

export const orgCode = (value) => {
  const pattern = /^[a-zA-Z0-9_-]{1,20}$/;

  return pattern.test(value);
};

export const password = (value) => {
  const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  return pattern.test(value);
};

export const phone = (value) => {
  const pattern = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  return pattern.test(value);
};

export const port = (value) => {
  const pattern = /^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/;

  return pattern.test(value);
};

// TODO: replace this with built-in URL validation once upgraded to vee-validate@^4.3
export const url = (value) => {
  const pattern = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,63})(\/.*)?$/;

  return pattern.test(value);
};

export const equals = (value, { to }) => {
  return value === to;
};

import { OPERATORS } from './filtering';
import { ASC } from './misc';

export const INGEST = 'ingest';
export const NORMALIZE = 'normalize';
export const PROCESS = 'process';
export const VALIDATE = 'validate';
export const DELIVER = 'deliver';
export const DISPATCH = 'dispatch';

export const DELIVERY_HISTORY_FILTERS = {
  currentDeliveryFilter: {
    operator: '',
    value: '',
  },

  status: {
    operator: OPERATORS.in,
    value: [],
  },
};

export const HEALTH_DASHBOARD_TOOLTIP_DELAY_SHOW = 500;
export const HEALTH_DASHBOARD_DEFAULT_DELIVERY_STATUS_CODE = 'DEFAULT';
export const HEALTH_DASHBOARD_DELIVERY_ERROR_CODES = [
  'CONNECTION_FAILURE',
  'CRUX_CONNECTOR_FAILURE',
  'FILE_NOT_FOUND',
  'DATA_NOT_FOUND',
  'FILE_DOWNLOAD_FAILED',
  'FILE_DOWNLOAD_INTERRUPTED',
  HEALTH_DASHBOARD_DEFAULT_DELIVERY_STATUS_CODE,
  'INVALID_CONFIGURATION_PARAMETER',
  'INVALID_CODEC',
  'VALIDATION_DATATYPE_FAILURE',
  'COLUMNS_MISMATCH_FAILURE',
  'COLUMNS_MISMATCH_FAILURE',
  'VALIDATION_HEADER_FAILURE',
  'DATA_QUALITY_FAILURE',
  'CRUX_CONNECTOR_FAILURE',
  'CRUX_NORMALIZER_FAILURE',
  'CONNECTION_EXCEPTION',
  'INVALID_CREDENTIALS',
  'ACCESS_DENIED',
  'CRUX_INTERNAL_FAILURE',
  'UNCLASSIFIED',
];

export const HEALTH_DASHBOARD_DATE_OPERATOR_OPTIONS = [
  { label: 'is before', value: OPERATORS.isBefore },
  { label: 'is after', value: OPERATORS.isAfter },
  { label: 'is on or before', value: OPERATORS.isOnOrBefore },
  { label: 'is on or after', value: OPERATORS.isOnOrAfter },
];

export const HEALTH_DASHBOARD_DATASET_STATUSES = {
  error: 'error',
  running: 'running',
  success: 'success',
};

export const HEALTH_DASHBOARD_FILTERS = {
  latestDeliveryFilter: {
    operator: '',
    value: '',
  },

  nextDeliveryFilter: {
    operator: '',
    value: '',
  },

  schedule: {
    operator: OPERATORS.is,
    value: '',
  },

  status: {
    operator: OPERATORS.in,
    value: [],
  },

  supplierName: {
    operator: OPERATORS.is,
    value: '',
  },

  type: {
    operator: OPERATORS.is,
    value: '',
  },
};

export const HEALTH_DASHBOARD_FILTERS_V2 = {
  deliveryStatus: [],
};

export const HEALTH_DASHBOARD_DEFAULT_SORT_SETTINGS = {
  latestDeliveryStatus: ASC,
};

// UI generated default status if not provided a value from the response
export const HEALTH_DASHBOARD_NOT_PROCESSED_STATUS = 'notProcessed';

export const HEALTH_DASHBOARD_ROUTE_NAME = 'health-dashboard';
export const HEALTH_DASHBOARD_DATASET_DELIVERY_ID_ROUTE_NAME =
  'health-dashboard-index-datasets-datasetId-deliveries-deliveryId';
export const HEALTH_DASHBOARD_DATASET_ROUTE_NAME = 'health-dashboard-index-datasets-datasetId';
export const HEALTH_DASHBOARD_ROUTES = [
  HEALTH_DASHBOARD_ROUTE_NAME,
  HEALTH_DASHBOARD_DATASET_ROUTE_NAME,
  HEALTH_DASHBOARD_DATASET_DELIVERY_ID_ROUTE_NAME,
];
export const HEALTH_DASHBOARD_STATUS_MAP = {
  error: 'error',
  late: 'late',
  missed: 'missed',
  running: 'running',
  success: 'success',
  warning: 'warning',
};

export const HEALTH_DASHBOARD_STATUS_MAP_V2 = {
  failed: 'FAILED',
  healthy: 'HEALTHY',
  late: 'DELIVERED_LATE',
  missing: 'MISSING',
};

export const HEALTH_DASHBOARD_BADGE_COLOR_MAP = {
  [HEALTH_DASHBOARD_STATUS_MAP.error]: 'red',
  [HEALTH_DASHBOARD_NOT_PROCESSED_STATUS]: 'gray',
  [HEALTH_DASHBOARD_STATUS_MAP.running]: 'blue',
  [HEALTH_DASHBOARD_STATUS_MAP.success]: 'green',
  [HEALTH_DASHBOARD_STATUS_MAP.warning]: 'yellow',
};

export const HEALTH_DASHBOARD_BADGE_COLOR_MAP_V2 = {
  [HEALTH_DASHBOARD_STATUS_MAP_V2.failed]: 'red',
  [HEALTH_DASHBOARD_NOT_PROCESSED_STATUS]: 'gray',
  [HEALTH_DASHBOARD_STATUS_MAP.running]: 'blue',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.healthy]: 'green',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.late]: 'yellow',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.missing]: 'gray',
};

export const HEALTH_DASHBOARD_ICON_SIZE = 'lg';
export const HEALTH_DASHBOARD_ICON_SPINNER_SIZE = 'xs';
export const HEALTH_DASHBOARD_ICON_COLOR_MAP = {
  [HEALTH_DASHBOARD_STATUS_MAP.error]: 'text-red-600',
  [HEALTH_DASHBOARD_NOT_PROCESSED_STATUS]: 'text-gray-500',
  [HEALTH_DASHBOARD_STATUS_MAP.success]: 'text-green-600',
  [HEALTH_DASHBOARD_STATUS_MAP.warning]: 'text-yellow-600',
};

export const HEALTH_DASHBOARD_ICON_COLOR_MAP_V2 = {
  [HEALTH_DASHBOARD_STATUS_MAP_V2.failed]: 'text-red-600',
  [HEALTH_DASHBOARD_NOT_PROCESSED_STATUS]: 'text-gray-600',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.healthy]: 'text-green-600',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.late]: 'text-yellow-500',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.missing]: 'text-gray-500',
};

export const HEALTH_DASHBOARD_ICON_NAME_MAP = {
  [HEALTH_DASHBOARD_STATUS_MAP.error]: 'error-warning-fill',
  [HEALTH_DASHBOARD_NOT_PROCESSED_STATUS]: 'play-circle-fill',
  [HEALTH_DASHBOARD_STATUS_MAP.success]: 'checkbox-circle-fill',
  [HEALTH_DASHBOARD_STATUS_MAP.warning]: 'alert-fill',
};

export const HEALTH_DASHBOARD_ICON_NAME_MAP_V2 = {
  [HEALTH_DASHBOARD_STATUS_MAP_V2.failed]: 'error-warning-fill',
  [HEALTH_DASHBOARD_NOT_PROCESSED_STATUS]: 'loader-2-line',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.healthy]: 'checkbox-circle-fill',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.late]: 'timer-line',
  [HEALTH_DASHBOARD_STATUS_MAP_V2.missing]: 'indeterminate-circle-fill',
};

export const HEALTH_DASHBOARD_STATUS_OPTIONS = [
  { label: 'Success', value: HEALTH_DASHBOARD_STATUS_MAP.success },
  { label: 'Warning', value: HEALTH_DASHBOARD_STATUS_MAP.warning },
  { label: 'Error', value: HEALTH_DASHBOARD_STATUS_MAP.error },
  { label: 'Running', value: HEALTH_DASHBOARD_STATUS_MAP.running },
];

export const HEALTH_DASHBOARD_STEP_MAP = {
  [DELIVER]: {
    icon: 'truck-line',
    title: DELIVER,
  },
  [INGEST]: {
    icon: 'upload-cloud-line',
    title: INGEST,
  },
  [NORMALIZE]: {
    icon: 'checkbox-multiple-line',
    title: NORMALIZE,
  },
  [PROCESS]: {
    icon: 'time-line',
    title: PROCESS,
  },
};
export const HEALTH_DASHBOARD_V2_ICON_SIZE = 'xl';

export const HEALTH_DASHBOARD_V2_DELIVERY_STEP_KEYS_MAP = {
  delivered: DISPATCH,
  normalized: NORMALIZE,
  processed: PROCESS,
  retrieved: INGEST,
  validated: VALIDATE,
};

export const HEALTH_DASHBOARD_V2_DELIVERY_STEP_STATUS_MAP = {
  failed: 'FAILED',
  success: 'SUCCESS',
};

export const HEALTH_DASHBOARD_DATASET_DELIVERY_TASKS_STATUS_MAP = {
  error: 'ERROR',
  failed: 'FAILURE',
  finish: 'FINISH',
  success: 'SUCCESS',
};

export const EMPTY_FORMATTED_FRAME = {
  [DELIVER]: [],
  [INGEST]: [],
  [NORMALIZE]: [],
  [PROCESS]: [],
};

import {
  CONSUMER_DATA_LICENSES,
  ORG_ACTIVE_STATUS,
  ORG_INACTIVE_STATUS,
  ORG_TYPES,
  ORGS_TABLE_FILTERS,
  SUBSCRIPTION_PLANS,
} from '../constants';

export const USER_SUBSCRIPTION = {
  endDate: 1679410252449,
  id: 'AzZiz7TYgiMXq2Kcf',
  itemPriceId: 'Pro-USD-Monthly',
  plan: SUBSCRIPTION_PLANS.pro,
  startDate: 1679409647649,
};

export const USER_SUBSCRIPTIONS_EDP_ACTIVE_FORGE_ACTIVE = {
  EDP: {
    endDate: 1679410252449,
    itemPriceId: 'Enterprise-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.enterprise,
    startDate: 1679409647649,
    status: ORG_ACTIVE_STATUS,
  },
  FORGE: {
    endDate: 1679410252449,
    itemPriceId: 'Crux-Forge-30-Annual-USD-Yearly',
    plan: SUBSCRIPTION_PLANS.cruxForgeAnnual,
    startDate: 1679409647649,
    status: ORG_ACTIVE_STATUS,
  },
  SUPPLIER: {
    endDate: 1679410252449,
    itemPriceId: 'Supplier-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.supplierAwaiting,
    startDate: 1679409647649,
    status: ORG_INACTIVE_STATUS,
  },
};

export const USER_SUBSCRIPTIONS_FORGE_AWAITING = {
  EDP: {
    endDate: 1679410252449,
    itemPriceId: 'Enterprise-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.enterprise,
    startDate: 1679409647649,
    status: ORG_ACTIVE_STATUS,
  },
  FORGE: {
    endDate: '',
    itemPriceId: '',
    plan: SUBSCRIPTION_PLANS.forgeAwaiting,
    startDate: '',
    status: ORG_INACTIVE_STATUS,
  },
  SUPPLIER: {
    endDate: '',
    itemPriceId: 'Supplier-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.supplierAwaiting,
    startDate: '',
    status: ORG_INACTIVE_STATUS,
  },
};

export const USER_SUBSCRIPTIONS_ONLY_EDP = {
  EDP: {
    endDate: 1679410252449,
    itemPriceId: 'Edp-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.edpAwaiting,
    startDate: 1679409647649,
    status: ORG_ACTIVE_STATUS,
  },
  FORGE: {
    endDate: 1679410252449,
    itemPriceId: 'Crux-Forge-30-Annual-USD-Yearly',
    plan: SUBSCRIPTION_PLANS.cruxForgeAnnual,
    startDate: 1679409647649,
    status: ORG_INACTIVE_STATUS,
  },
  SUPPLIER: {
    endDate: 1679410252449,
    itemPriceId: 'Supplier-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.supplierAwaiting,
    startDate: 1679409647649,
    status: ORG_INACTIVE_STATUS,
  },
};

export const USER_SUBSCRIPTIONS_ONLY_FORGE = {
  EDP: {
    endDate: 1679410252449,
    itemPriceId: 'Edp-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.edpAwaiting,
    startDate: 1679409647649,
    status: ORG_INACTIVE_STATUS,
  },
  FORGE: {
    endDate: 1679410252449,
    itemPriceId: 'Crux-Forge-30-Annual-USD-Yearly',
    plan: SUBSCRIPTION_PLANS.cruxForgeAnnual,
    startDate: 1679409647649,
    status: ORG_ACTIVE_STATUS,
  },
  SUPPLIER: {
    endDate: 1679410252449,
    itemPriceId: 'Supplier-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.supplierAwaiting,
    startDate: 1679409647649,
    status: ORG_INACTIVE_STATUS,
  },
};

export const USER_SUBSCRIPTIONS_SUPPLIER_ACTIVE = {
  EDP: {
    endDate: 1679410252449,
    itemPriceId: 'Edp-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.edpAwaiting,
    startDate: 1679409647649,
    status: ORG_INACTIVE_STATUS,
  },
  FORGE: {
    endDate: '',
    itemPriceId: 'Forge-Awaiting-Subscription-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.forgeAwaiting,
    startDate: '',
    status: ORG_INACTIVE_STATUS,
  },
  SUPPLIER: {
    endDate: 1679410252449,
    itemPriceId: 'Supplier-USD-Monthly',
    plan: SUBSCRIPTION_PLANS.supplier,
    startDate: 1679409647649,
    status: ORG_ACTIVE_STATUS,
  },
};

export const ORG_WITH_ACTIVE_EDP_ACTIVE_FORGE = {
  attr: {
    address: '',
    city: '',
    country: '',
    emailDomains: ['woowoo.com'],
    status: ORG_ACTIVE_STATUS,
    subscription: USER_SUBSCRIPTION,
    subscriptions: USER_SUBSCRIPTIONS_EDP_ACTIVE_FORGE_ACTIVE,
    supportAddress: '',
    zip: '',
  },
  consumerDataLicenses: {
    [CONSUMER_DATA_LICENSES.cusip]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.other]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.sedol]: {
      enabled: false,
    },
  },
  createdAt: '2018-10-16T22:13:23.000+00:00',
  description: 'Crux Test Organization',
  filterCatalogBySuppliers: [],
  id: 'Orj0pgJw',
  legacyId: 'IQFj0pgJw7Lzcov6ggjDDgynlg',
  markedComplete: false,
  modifiedAt: '2019-12-05T03:53:51.341+00:00',
  name: 'Crux',
  status: ORG_ACTIVE_STATUS,
  subscription: USER_SUBSCRIPTION,
  subscriptions: USER_SUBSCRIPTIONS_EDP_ACTIVE_FORGE_ACTIVE,
  type: ORG_TYPES.consumer,
  website: 'www.cruxdata.com',
};

export const ORG_WITH_FORGE_AWAITING = {
  attr: {
    address: '',
    city: '',
    country: '',
    status: ORG_ACTIVE_STATUS,
    subscription: USER_SUBSCRIPTION,
    subscriptions: USER_SUBSCRIPTIONS_FORGE_AWAITING,
    supportAddress: '',
    zip: '',
  },
  code: 'FORTUNE',
  consumerDataLicenses: {
    [CONSUMER_DATA_LICENSES.cusip]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.other]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.sedol]: {
      enabled: false,
    },
  },
  createdAt: '2022-11-16T17:58:32.259+00:00',
  description: 'Fortune Test Organization',
  filterCatalogBySuppliers: [],
  id: 'Or8oYDo-',
  legacyId: 'IQF8oYDo-_fIP2zwEZF0u-00sw',
  markedComplete: false,
  modifiedAt: '2022-11-16T17:58:32.259+00:00',
  name: 'Fortune',
  status: ORG_ACTIVE_STATUS,
  subscription: USER_SUBSCRIPTION,
  subscriptions: USER_SUBSCRIPTIONS_FORGE_AWAITING,
  type: ORG_TYPES.consumer,
  website: 'www.fortune.com',
};

export const ORG_WITH_ACTIVE_SUPPLIER = {
  attr: {
    address: '',
    city: '',
    country: '',
    status: ORG_ACTIVE_STATUS,
    subscription: USER_SUBSCRIPTION,
    subscriptions: USER_SUBSCRIPTIONS_SUPPLIER_ACTIVE,
    supportAddress: '',
    zip: '',
  },
  code: 'FORTUNE',
  consumerDataLicenses: {
    [CONSUMER_DATA_LICENSES.cusip]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.other]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.sedol]: {
      enabled: false,
    },
  },
  createdAt: '2022-11-16T17:58:32.259+00:00',
  description: 'Fortune Test Organization',
  filterCatalogBySuppliers: [],
  id: 'Or8oYDo-',
  legacyId: 'IQF8oYDo-_fIP2zwEZF0u-00sw',
  markedComplete: false,
  modifiedAt: '2022-11-16T17:58:32.259+00:00',
  name: 'Fortune',
  status: ORG_ACTIVE_STATUS,
  subscription: USER_SUBSCRIPTION,
  subscriptions: USER_SUBSCRIPTIONS_SUPPLIER_ACTIVE,
  type: ORG_TYPES.supplier,
  website: 'www.fortune.com',
};

export const DEACTIVATED_ORG = {
  attr: {
    address: '1234 Banana St',
    city: '',
    country: '',
    deactivatedAt: '2023-03-13T03:53:51.341+00:00',
    state: 'TN',
    subscription: USER_SUBSCRIPTION,
    subscriptions: USER_SUBSCRIPTIONS_EDP_ACTIVE_FORGE_ACTIVE,
    zip: '123456',
  },
  consumerDataLicenses: {
    [CONSUMER_DATA_LICENSES.cusip]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.other]: {
      enabled: false,
    },
    [CONSUMER_DATA_LICENSES.sedol]: {
      enabled: false,
    },
  },
  createdAt: '2018-10-16T22:13:23.000+00:00',
  description: 'Crux Test Organization',
  filterCatalogBySuppliers: [],
  id: 'Orj0pgJw',
  legacyId: 'IQFj0pgJw7Lzcov6ggjDDgynlg',
  markedComplete: false,
  modifiedAt: '2019-12-05T03:53:51.341+00:00',
  name: 'Crux',
  status: ORG_INACTIVE_STATUS,
  subscription: USER_SUBSCRIPTION,
  subscriptions: USER_SUBSCRIPTIONS_EDP_ACTIVE_FORGE_ACTIVE,
  website: 'https://www.cruxdata.com',
};

export const ORGS = [
  ORG_WITH_ACTIVE_EDP_ACTIVE_FORGE,
  ORG_WITH_FORGE_AWAITING,
  ORG_WITH_ACTIVE_SUPPLIER,
  DEACTIVATED_ORG,
];

export const ORG_ACTIVITY_MONTHS = [
  {
    data: {
      activeDataProducts: 5,
      draftDataProducts: 3,
      rowsDelivered: 25,
    },
    date: '2022-01-21T18',
  },
  {
    data: {
      activeDataProducts: 20,
      draftDataProducts: 5,
      rowsDelivered: 50,
    },
    date: '2022-02-21T18',
  },
  {
    data: {
      activeDataProducts: 32,
      draftDataProducts: 9,
      rowsDelivered: 100,
    },
    date: '2022-03-21T18',
  },
];

export const ACTIVE_ORGS_FILTERS = {
  ...ORGS_TABLE_FILTERS,
  status: {
    ...ORGS_TABLE_FILTERS.status,
    value: [ORG_ACTIVE_STATUS],
  },
  type: {
    ...ORGS_TABLE_FILTERS.type,
    value: ORG_TYPES.consumer,
  },
};

export const DEACTIVATED_ORGS_FILTERS = {
  ...ORGS_TABLE_FILTERS,
  status: {
    ...ORGS_TABLE_FILTERS.status,
    value: [ORG_INACTIVE_STATUS],
  },
  type: {
    ...ORGS_TABLE_FILTERS.type,
    value: ORG_TYPES.supplier,
  },
};

export const ALL_ORGS_LOCAL_FILTER = {
  ...ORGS_TABLE_FILTERS,
  status: {
    active: false,
    deactivated: false,
  },
  type: {
    consumer: false,
    supplier: false,
  },
};

export const ACTIVE_ORGS_LOCAL_FILTER = {
  ...ORGS_TABLE_FILTERS,
  status: {
    active: true,
    deactivated: false,
  },
  type: {
    consumer: true,
    supplier: false,
  },
};

export const ORG_DATA_PRODUCT_ENTITLEMENT_REQUEST = {
  cduId: '018481',
  license: {
    type: 'Open',
    validFrom: '2024-08-20',
    validTo: '2025-08-15',
  },
  orgId: 'OrcgmLZg',
  serviceEndDate: '2025-08-15',
  serviceStartDate: '2024-08-20',
  serviceType: 'Trial',
  status: 'Active',
};

export const ORG_DATA_PRODUCT_ENTITLEMENT_RESPONSE = {
  cduId: 'Cx018481',
  config: null,
  createdAt: '2024-08-19T16:08:59.279+0000',
  dataProductId: 'PrD9b5h3S5mr',
  id: 'Suffe4cd7b483005',
  itemPriceId: null,
  license: {
    type: 'Open',
    validFrom: '2024-08-20T00:00:00.000+0000',
    validTo: '2025-08-15T00:00:00.000+0000',
  },
  metering: {
    status: 'ACTIVE',
    subscriptionType: 'FORGE',
  },
  modifiedAt: '2024-08-19T16:08:59.279+0000',
  orgId: 'OrcgmLZg',
  serviceEndDate: '2025-08-15T00:00:00.000+0000',
  serviceStartDate: '2024-08-20T00:00:00.000+0000',
  serviceType: 'Trial',
  status: 'Active',
  type: 'gcs',
};

export const ORG_STATS = {
  active: 12,
  cruxForgeAnnual: 12,
  enterprise: 12,
  free: 12,
  inactive: 12,
  pro: 12,
  subscription: {
    plan: 'FREE',
  },
  total: 12,
  trial: 12,
};

export const EDP_ACTIVE_SUBSCRIPTION_PROPS = {
  cardPalette: {
    bgColor: 'bg-purple-50',
    bgColorDark: 'bg-purple-400',
    bgColorSolid: 'bg-purple-700',
    borderColor: 'border-purple-200',
    borderColorDark: 'border-purple-400',
    buttonColor: 'purple',
    buttonColorOutlined: 'purple-outline',
    textColor: 'text-purple-700',
    textColorDark: 'text-purple-400',
  },
  daysLeftText: '8 daysLeft',
  enrolledOn: 'enrolledOn March 21, 2023.',
  enrolledOnDate: 'March 21, 2023',
  expiresOn: 'expiresOn March 21, 2023.',
  expiresOnDate: 'March 21, 2023',
  hasEndDate: true,
  iconColor: 'text-purple-600',
  iconName: 'vip-crown-line',
  isPlanExpired: false,
  plan: 'ENTERPRISE',
  shouldShowDaysLeft: true,
};

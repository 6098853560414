export const ALPHA_WITH_NUMBERS = 'Illegal character. Field names can contain only alphanumerical characters.';
export const ALPHA_DASH_WITH_SPACE = 'Special characters are not allowed';
export const ALPHA_WITH_UNDERSCORES =
  'Illegal character. Field names can contain only alphanumerical characters or underscores.';
export const CRON_END_TIME = 'Select a Stop looking time that is later than the Start looking time.';
export const CRON_INTERVAL = 'Please check to make sure that "Stop looking" value corresponds with "Look every" value.';
export const DATE_RANGE_START_REQUIRED = 'Start date is required.';
export const ONE_FIELD_REQUIRED = 'This field is required. Please select at least one.';
export const FIRST_CHARACTER_NOT_NUMBER =
  'The field name cannot start with a number. Start with either a letter or an underscore.';
export const FULL_NAME = 'First and last name required.';
export const INVALID_EMAIL = 'This field must be a valid email.';
export const INVALID_ORG_CODE = 'This field must be a valid organization code.';
export const INVALID_URL = 'This field must be a valid URL.';
export const MAX = 'This field is too long.';
export const MIN = 'This field is too short.';
export const NOT_ONE_OF = 'This field must be a unique value.';
export const ONE_CHILD_FIELD_REQUIRED = 'At least one child field required.';
export const CONFIRM_PASSWORD = 'Does not match password';
export const PASSWORD =
  'This field must contain at least 8 characters, a lowercase letter, an uppercase letter, a number, and no parts of your username.';
export const PHONE = 'This must be a valid phone number.';
export const REQUIRED = 'This field is required.';
export const EMAIL_DOMAINS = 'This field contains one or more invalid domains.';
export const EQUALS = 'The field value is not equal to';

export const VALIDATION_MESSAGES_MAP = {
  alphaDashWithSpace: ALPHA_DASH_WITH_SPACE,
  alphaNum: ALPHA_WITH_NUMBERS,
  alphaWithUnderscores: ALPHA_WITH_UNDERSCORES,
  confirmPassword: CONFIRM_PASSWORD,
  cronEndTime: CRON_END_TIME,
  cronInterval: CRON_INTERVAL,
  dateRangeStartRequired: DATE_RANGE_START_REQUIRED,
  email: INVALID_EMAIL,
  emailDomains: EMAIL_DOMAINS,
  equals: EQUALS,
  firstCharacterNotNumber: FIRST_CHARACTER_NOT_NUMBER,
  fullName: FULL_NAME,
  max: MAX,
  min: MIN,
  notOneOf: NOT_ONE_OF,
  oneChildFieldRequired: ONE_CHILD_FIELD_REQUIRED,
  oneFieldRequired: ONE_FIELD_REQUIRED,
  orgCode: INVALID_ORG_CODE,
  password: PASSWORD,
  phone: PHONE,
  required: REQUIRED,
  url: INVALID_URL,
};

import dayjs from 'dayjs';

export default {
  addDays(date, days) {
    date.setDate(date.getDate() + days);

    return date;
  },

  dateIsBefore(endDate, startDate) {
    return dayjs(endDate).isBefore(dayjs(startDate));
  },

  subtractDays(date, days) {
    date.setDate(date.getDate() - days);

    return date;
  },

  today() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return today;
  },
};

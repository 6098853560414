export default () => {
  return {
    customPatterns: [],

    deletedFoundPatterns: [],

    foundPatterns: [],

    matchingFiles: {},

    selectedPaths: [],

    selectedPatterns: [],
  };
};

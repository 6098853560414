import { START_LOADING, STOP_LOADING } from './mutation-types';

export default {
  startLoading({ commit }, action) {
    commit(START_LOADING, action);
  },

  stopLoading({ commit }, action) {
    commit(STOP_LOADING, action);
  },
};

import { uniq } from 'lodash-es';

import {
  SET_DELETED_FOUND_PATTERNS,
  SET_FOUND_PATTERNS,
  SET_MATCHING_FILES,
  SET_SELECTED_PATTERNS,
  SET_SELECTED_PATHS,
  SET_CUSTOM_PATTERNS,
  RESET_DATA,
} from './mutation-types';

export default {
  [RESET_DATA](state) {
    state.customPatterns = [];
    state.deletedFoundPatterns = [];
    state.foundPatterns = [];
    state.matchingFiles = {};
    state.selectedPaths = [];
    state.selectedPatterns = [];
  },

  [SET_CUSTOM_PATTERNS](state, customPatterns) {
    state.customPatterns = uniq(customPatterns).sort();
  },

  [SET_DELETED_FOUND_PATTERNS](state, deletedFoundPatterns) {
    state.deletedFoundPatterns = deletedFoundPatterns;
  },

  [SET_FOUND_PATTERNS](state, foundPatterns) {
    state.foundPatterns = foundPatterns;
  },

  [SET_MATCHING_FILES](state, { matchingFiles, pattern }) {
    state.matchingFiles[pattern] = matchingFiles[0];
  },

  [SET_SELECTED_PATHS](state, selectedPaths) {
    state.selectedPaths = uniq(selectedPaths).sort();
  },

  [SET_SELECTED_PATTERNS](state, selectedPatterns) {
    state.selectedPatterns = uniq(selectedPatterns).sort();
  },
};

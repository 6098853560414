import { default as agreementY82GsiR81fMeta } from "/app/pages/agreement.vue?macro=true";
import { default as _91frameId_93z2MZCEd859Meta } from "/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId]/frames/[frameId].vue?macro=true";
import { default as _91datasetId_93xbo0f2qaA0Meta } from "/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId].vue?macro=true";
import { default as _91dataProductId_93THKp6A0lFNMeta } from "/app/pages/catalog/data-products/[dataProductId].vue?macro=true";
import { default as indexETrUrt5Zz3Meta } from "/app/pages/catalog/data-products/index.vue?macro=true";
import { default as data_45productsOG14AKOwg7Meta } from "/app/pages/catalog/data-products.vue?macro=true";
import { default as _91frameId_93GSVvxlgYthMeta } from "/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId]/frames/[frameId].vue?macro=true";
import { default as _91datasetId_93gzNomvVGINMeta } from "/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId].vue?macro=true";
import { default as _91dataProductId_93xBD4lpEW6BMeta } from "/app/pages/catalog/families/[familyName]/[dataProductId].vue?macro=true";
import { default as indexoIkbk4HmrJMeta } from "/app/pages/catalog/families/[familyName]/index.vue?macro=true";
import { default as _91familyName_93doxNPA0f6PMeta } from "/app/pages/catalog/families/[familyName].vue?macro=true";
import { default as indexdGpzbD6lZrMeta } from "/app/pages/catalog/families/index.vue?macro=true";
import { default as familiesDUKwlFbHc8Meta } from "/app/pages/catalog/families.vue?macro=true";
import { default as indexpGvfXeF4yPMeta } from "/app/pages/catalog/suppliers/[supplierId]/index.vue?macro=true";
import { default as index8HgT1nGNymMeta } from "/app/pages/catalog/suppliers/index.vue?macro=true";
import { default as suppliersZfYqbO0v0oMeta } from "/app/pages/catalog/suppliers.vue?macro=true";
import { default as catalogmbyzFSoe6qMeta } from "/app/pages/catalog.vue?macro=true";
import { default as index7lAEebvypIMeta } from "/app/pages/connection-settings/index.vue?macro=true";
import { default as connection_45settingsFNhlhsKhgOMeta } from "/app/pages/connection-settings.vue?macro=true";
import { default as indexhg2nWJrbRRMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as ExploreiH59Pex9OkMeta } from "/app/pages/dashboard/subcomponents/Explore.vue?macro=true";
import { default as ExploreCard8fgeEVgGnKMeta } from "/app/pages/dashboard/subcomponents/ExploreCard.vue?macro=true";
import { default as GettingStarted5vIjHQWLmmMeta } from "/app/pages/dashboard/subcomponents/GettingStarted.vue?macro=true";
import { default as GettingStartedCard80CAvnsVG5Meta } from "/app/pages/dashboard/subcomponents/GettingStartedCard.vue?macro=true";
import { default as ShortcutCardRESEVA0NWOMeta } from "/app/pages/dashboard/subcomponents/ShortcutCard.vue?macro=true";
import { default as ShortcutsPTQoEcfB36Meta } from "/app/pages/dashboard/subcomponents/Shortcuts.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91frameId_93Un47NOrV4tMeta } from "/app/pages/data-products/[id]/details-next/datasets/[datasetId]/frames/[frameId].vue?macro=true";
import { default as _91datasetId_93JPdFGVlWHnMeta } from "/app/pages/data-products/[id]/details-next/datasets/[datasetId].vue?macro=true";
import { default as details_45nextFb8h59T8xgMeta } from "/app/pages/data-products/[id]/details-next.vue?macro=true";
import { default as documentationSB3sVzqu6eMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/documentation.vue?macro=true";
import { default as framesgtkNfOIe4wMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/frames.vue?macro=true";
import { default as historynkdj2mYaiFMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/history.vue?macro=true";
import { default as indexyfZqSZhOKAMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/index.vue?macro=true";
import { default as notification_45ruleLlJtYUpU0dMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/notification-rule.vue?macro=true";
import { default as resultsBTXtg3u4fYMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/results.vue?macro=true";
import { default as scheduleYjp5eHxNpwMeta } from "/app/pages/data-products/[id]/details/datasets/[datasetId]/schedule.vue?macro=true";
import { default as index9zJZEB0jG1Meta } from "/app/pages/data-products/[id]/details/datasets/index.vue?macro=true";
import { default as historycqzmq5kJx8Meta } from "/app/pages/data-products/[id]/details/history.vue?macro=true";
import { default as index7yTHezPipUMeta } from "/app/pages/data-products/[id]/details/index.vue?macro=true";
import { default as index0QROvc3LbHMeta } from "/app/pages/data-products/[id]/details/results/index.vue?macro=true";
import { default as summary6veJsaEMofMeta } from "/app/pages/data-products/[id]/details/summary.vue?macro=true";
import { default as detailsySbTGOyxcIMeta } from "/app/pages/data-products/[id]/details.vue?macro=true";
import { default as datasets0iqI9MzvUsMeta } from "/app/pages/data-products/[id]/edit/datasets.vue?macro=true";
import { default as destinationssQR0qXZHGEMeta } from "/app/pages/data-products/[id]/edit/destinations.vue?macro=true";
import { default as indexeJBeo05DY6Meta } from "/app/pages/data-products/[id]/edit/index.vue?macro=true";
import { default as schemasU320h7uYpRMeta } from "/app/pages/data-products/[id]/edit/schemas.vue?macro=true";
import { default as editBLrexcbMJYMeta } from "/app/pages/data-products/[id]/edit.vue?macro=true";
import { default as indexK1dzq5JKozMeta } from "/app/pages/data-products/[id]/index.vue?macro=true";
import { default as indexUkAlVXacdSMeta } from "/app/pages/data-products/[id]/manage/index.vue?macro=true";
import { default as settingsrt84SCsY4VMeta } from "/app/pages/data-products/[id]/manage/settings.vue?macro=true";
import { default as subscribersAL7Z9yBBXIMeta } from "/app/pages/data-products/[id]/manage/subscribers.vue?macro=true";
import { default as subscriptionYJ1XcBqeeLMeta } from "/app/pages/data-products/[id]/manage/subscription.vue?macro=true";
import { default as managezhSM21OJHzMeta } from "/app/pages/data-products/[id]/manage.vue?macro=true";
import { default as connectionENGaQnamwAMeta } from "/app/pages/data-products/[id]/scratch/connection.vue?macro=true";
import { default as index4wozJ0ddbjMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/[frameId]/index.vue?macro=true";
import { default as indexJ6UFKd4LWQMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/index.vue?macro=true";
import { default as indexjpbDtdZRmrMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/index.vue?macro=true";
import { default as indexSwwjKcPue7Meta } from "/app/pages/data-products/[id]/scratch/data-modeling/datasets/index.vue?macro=true";
import { default as indexbqrCVeJbUOMeta } from "/app/pages/data-products/[id]/scratch/data-modeling/index.vue?macro=true";
import { default as data_45modelingP8fX3XtLSdMeta } from "/app/pages/data-products/[id]/scratch/data-modeling.vue?macro=true";
import { default as destinationskq4U6KTEU8Meta } from "/app/pages/data-products/[id]/scratch/destinations.vue?macro=true";
import { default as finalizeSC5HgtJuMkMeta } from "/app/pages/data-products/[id]/scratch/finalize.vue?macro=true";
import { default as indexKvenRT6i11Meta } from "/app/pages/data-products/[id]/scratch/notification-rules/datasets/[datasetId]/index.vue?macro=true";
import { default as index3Hs9ls4GF3Meta } from "/app/pages/data-products/[id]/scratch/notification-rules/datasets/index.vue?macro=true";
import { default as notification_45rulesFVIxsCkHrZMeta } from "/app/pages/data-products/[id]/scratch/notification-rules.vue?macro=true";
import { default as profileJLUj5GtjbPMeta } from "/app/pages/data-products/[id]/scratch/profile.vue?macro=true";
import { default as scheduleN1kXVPCprAMeta } from "/app/pages/data-products/[id]/scratch/schedule.vue?macro=true";
import { default as scratch06k8wkTd4tMeta } from "/app/pages/data-products/[id]/scratch.vue?macro=true";
import { default as _91id_933HxNXI5qt6Meta } from "/app/pages/data-products/[id].vue?macro=true";
import { default as entitlementsWlBiIrjWVpMeta } from "/app/pages/data-products/index/entitlements.vue?macro=true";
import { default as indexeH0okA8DSJMeta } from "/app/pages/data-products/index.vue?macro=true";
import { default as data_45productswATmH8QrEuMeta } from "/app/pages/data-products.vue?macro=true";
import { default as indexz1CkRA7HxDMeta } from "/app/pages/health-dashboard/index/datasets/[datasetId]/deliveries/[deliveryId]/index.vue?macro=true";
import { default as _91datasetId_93QqxJvXhzL9Meta } from "/app/pages/health-dashboard/index/datasets/[datasetId].vue?macro=true";
import { default as indexRc45tXpioqMeta } from "/app/pages/health-dashboard/index.vue?macro=true";
import { default as health_45dashboarduKP3YD6NTIMeta } from "/app/pages/health-dashboard.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as settingsvwJZzkXsMxMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/settings.vue?macro=true";
import { default as subscribers7188R6uRtYMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/subscribers.vue?macro=true";
import { default as subscriptionf4UOb57ZvBMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId]/subscription.vue?macro=true";
import { default as _91dataProductId_93FwtbWQ0xqqMeta } from "/app/pages/orgs/[id]/data-products/[dataProductId].vue?macro=true";
import { default as data_45productsCIEzKEH1xVMeta } from "/app/pages/orgs/[id]/data-products.vue?macro=true";
import { default as entitlementsdSFCHgajzwMeta } from "/app/pages/orgs/[id]/index/entitlements.vue?macro=true";
import { default as indexMXN3VSUzk9Meta } from "/app/pages/orgs/[id]/index.vue?macro=true";
import { default as planWXEMrbJsHAMeta } from "/app/pages/orgs/[id]/plan.vue?macro=true";
import { default as _91id_934o8ocNME0FMeta } from "/app/pages/orgs/[id].vue?macro=true";
import { default as usersdJm5dKrN5DMeta } from "/app/pages/orgs/create/[id]/users.vue?macro=true";
import { default as detailsNERmXDwwxHMeta } from "/app/pages/orgs/create/[type]/details.vue?macro=true";
import { default as nameHbxyXXyV5vMeta } from "/app/pages/orgs/create/[type]/name.vue?macro=true";
import { default as plansMIRGVTT5WsMeta } from "/app/pages/orgs/create/[type]/plans.vue?macro=true";
import { default as indexz93q7se4BFMeta } from "/app/pages/orgs/create/index.vue?macro=true";
import { default as create4muSqvBn4VMeta } from "/app/pages/orgs/create.vue?macro=true";
import { default as indexF331mkLKAPMeta } from "/app/pages/orgs/index.vue?macro=true";
import { default as typeRSoqmqNQfnMeta } from "/app/pages/orgs/type.vue?macro=true";
import { default as orgsMF7Scv4SIcMeta } from "/app/pages/orgs.vue?macro=true";
import { default as index7lupYtO9UwMeta } from "/app/pages/password/[action]/[token]/[email]/index.vue?macro=true";
import { default as recover_45passwordFzhfEITEoUMeta } from "/app/pages/recover-password.vue?macro=true";
import { default as api_45keysOPkVJFbql7Meta } from "/app/pages/settings/api-keys.vue?macro=true";
import { default as generalTBeWhysYVLMeta } from "/app/pages/settings/general.vue?macro=true";
import { default as index11eXGkSJBcMeta } from "/app/pages/settings/index.vue?macro=true";
import { default as emailfzsAt6EdroMeta } from "/app/pages/settings/notifications/channels/email.vue?macro=true";
import { default as indexnl571opJf3Meta } from "/app/pages/settings/notifications/channels/index.vue?macro=true";
import { default as pub_45subeRJpIHhiVoMeta } from "/app/pages/settings/notifications/channels/pub-sub.vue?macro=true";
import { default as sqsykvW2zXVoBMeta } from "/app/pages/settings/notifications/channels/sqs.vue?macro=true";
import { default as webhookA7DeN2kyhnMeta } from "/app/pages/settings/notifications/channels/webhook.vue?macro=true";
import { default as channels1pdq4LMJIUMeta } from "/app/pages/settings/notifications/channels.vue?macro=true";
import { default as index98zCyyEGM5Meta } from "/app/pages/settings/notifications/index.vue?macro=true";
import { default as preferencesnkz4YeqFldMeta } from "/app/pages/settings/notifications/preferences.vue?macro=true";
import { default as editxa8a7gc3FzMeta } from "/app/pages/settings/notifications/rules/[id]/edit.vue?macro=true";
import { default as indexaSgsRjD42zMeta } from "/app/pages/settings/notifications/rules/[id]/index.vue?macro=true";
import { default as createIZgECtdVJXMeta } from "/app/pages/settings/notifications/rules/create.vue?macro=true";
import { default as indexlMBqU4uvQ9Meta } from "/app/pages/settings/notifications/rules/index.vue?macro=true";
import { default as notifications2EXqO5sHnDMeta } from "/app/pages/settings/notifications.vue?macro=true";
import { default as indexZnsbpzMDVSMeta } from "/app/pages/settings/plan-details/index.vue?macro=true";
import { default as upgradeUsqLjinHuqMeta } from "/app/pages/settings/plan-details/upgrade.vue?macro=true";
import { default as your_45plan4qLY13B55nMeta } from "/app/pages/settings/plan-details/your-plan.vue?macro=true";
import { default as plan_45details1y8jXKABDOMeta } from "/app/pages/settings/plan-details.vue?macro=true";
import { default as history8i4dFxIa5kMeta } from "/app/pages/settings/usage/history.vue?macro=true";
import { default as indexlKLobfYM9eMeta } from "/app/pages/settings/usage/index.vue?macro=true";
import { default as usagelSxxSINus2Meta } from "/app/pages/settings/usage.vue?macro=true";
import { default as add_45user3it1rpHlobMeta } from "/app/pages/settings/user-management/add-user.vue?macro=true";
import { default as user_45managementNlGkd4mCWzMeta } from "/app/pages/settings/user-management.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as customRxlV2gug1mMeta } from "/app/pages/studio/data-products/[dataProductId]/connect/custom.vue?macro=true";
import { default as existingVndcTwbJN3Meta } from "/app/pages/studio/data-products/[dataProductId]/connect/existing.vue?macro=true";
import { default as newIQwvPoLw2hMeta } from "/app/pages/studio/data-products/[dataProductId]/connect/new.vue?macro=true";
import { default as connectSes7UOs9sdMeta } from "/app/pages/studio/data-products/[dataProductId]/connect.vue?macro=true";
import { default as destinationsm8Vwv93kXGMeta } from "/app/pages/studio/data-products/[dataProductId]/destinations.vue?macro=true";
import { default as indexp43YoK3EGOMeta } from "/app/pages/studio/data-products/[dataProductId]/index.vue?macro=true";
import { default as indexr2UnfR6TfDMeta } from "/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/frames/[frameId]/index.vue?macro=true";
import { default as indexWGBFV0ObHnMeta } from "/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/index.vue?macro=true";
import { default as indexaW3CFKLIrFMeta } from "/app/pages/studio/data-products/[dataProductId]/model/index.vue?macro=true";
import { default as modelVmGhcA8jHUMeta } from "/app/pages/studio/data-products/[dataProductId]/model.vue?macro=true";
import { default as _91frameId_93F05ToIub1FMeta } from "/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/frames/[frameId].vue?macro=true";
import { default as _91datasetId_93jcPi1ChD0AMeta } from "/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId].vue?macro=true";
import { default as reviewGPAZAO3lE0Meta } from "/app/pages/studio/data-products/[dataProductId]/review.vue?macro=true";
import { default as _91datasetId_93wOTrbSchELMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule/datasets/[datasetId].vue?macro=true";
import { default as indextF1bcGhB2iMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule/datasets/index.vue?macro=true";
import { default as schedulekxcIBbWnzzMeta } from "/app/pages/studio/data-products/[dataProductId]/schedule.vue?macro=true";
import { default as select_45dataMpThs0wqvoMeta } from "/app/pages/studio/data-products/[dataProductId]/select-data.vue?macro=true";
import { default as _91dataProductId_93MZnh1RgD3RMeta } from "/app/pages/studio/data-products/[dataProductId].vue?macro=true";
import { default as createXxlGqWqVaCMeta } from "/app/pages/studio/data-products/create.vue?macro=true";
import { default as data_45productsibXhjkc3KuMeta } from "/app/pages/studio/data-products.vue?macro=true";
import { default as studioyhbHREGAZRMeta } from "/app/pages/studio.vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
export default [
  {
    name: "agreement",
    path: "/agreement",
    meta: agreementY82GsiR81fMeta || {},
    component: () => import("/app/pages/agreement.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    meta: catalogmbyzFSoe6qMeta || {},
    component: () => import("/app/pages/catalog.vue"),
    children: [
  {
    name: data_45productsOG14AKOwg7Meta?.name,
    path: "data-products",
    component: () => import("/app/pages/catalog/data-products.vue"),
    children: [
  {
    name: "catalog-data-products-dataProductId",
    path: ":dataProductId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId].vue"),
    children: [
  {
    name: "catalog-data-products-dataProductId-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId].vue"),
    children: [
  {
    name: "catalog-data-products-dataProductId-datasets-datasetId-frames-frameId",
    path: "frames/:frameId()",
    component: () => import("/app/pages/catalog/data-products/[dataProductId]/datasets/[datasetId]/frames/[frameId].vue")
  }
]
  }
]
  },
  {
    name: "catalog-data-products",
    path: "",
    component: () => import("/app/pages/catalog/data-products/index.vue")
  }
]
  },
  {
    name: familiesDUKwlFbHc8Meta?.name,
    path: "families",
    component: () => import("/app/pages/catalog/families.vue"),
    children: [
  {
    name: _91familyName_93doxNPA0f6PMeta?.name,
    path: ":familyName()",
    component: () => import("/app/pages/catalog/families/[familyName].vue"),
    children: [
  {
    name: "catalog-families-familyName-dataProductId",
    path: ":dataProductId()",
    component: () => import("/app/pages/catalog/families/[familyName]/[dataProductId].vue"),
    children: [
  {
    name: "catalog-families-familyName-dataProductId-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId].vue"),
    children: [
  {
    name: "catalog-families-familyName-dataProductId-datasets-datasetId-frames-frameId",
    path: "frames/:frameId()",
    component: () => import("/app/pages/catalog/families/[familyName]/[dataProductId]/datasets/[datasetId]/frames/[frameId].vue")
  }
]
  }
]
  },
  {
    name: "catalog-families-familyName",
    path: "",
    component: () => import("/app/pages/catalog/families/[familyName]/index.vue")
  }
]
  },
  {
    name: "catalog-families",
    path: "",
    component: () => import("/app/pages/catalog/families/index.vue")
  }
]
  },
  {
    name: suppliersZfYqbO0v0oMeta?.name,
    path: "suppliers",
    component: () => import("/app/pages/catalog/suppliers.vue"),
    children: [
  {
    name: "catalog-suppliers-supplierId",
    path: ":supplierId()",
    component: () => import("/app/pages/catalog/suppliers/[supplierId]/index.vue")
  },
  {
    name: "catalog-suppliers",
    path: "",
    component: () => import("/app/pages/catalog/suppliers/index.vue")
  }
]
  }
]
  },
  {
    name: connection_45settingsFNhlhsKhgOMeta?.name,
    path: "/connection-settings",
    meta: connection_45settingsFNhlhsKhgOMeta || {},
    component: () => import("/app/pages/connection-settings.vue"),
    children: [
  {
    name: "connection-settings",
    path: "",
    component: () => import("/app/pages/connection-settings/index.vue")
  }
]
  },
  {
    name: dashboardJu37u7k0toMeta?.name,
    path: "/dashboard",
    meta: dashboardJu37u7k0toMeta || {},
    component: () => import("/app/pages/dashboard.vue"),
    children: [
  {
    name: "dashboard",
    path: "",
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-subcomponents-Explore",
    path: "subcomponents/Explore",
    component: () => import("/app/pages/dashboard/subcomponents/Explore.vue")
  },
  {
    name: "dashboard-subcomponents-ExploreCard",
    path: "subcomponents/ExploreCard",
    component: () => import("/app/pages/dashboard/subcomponents/ExploreCard.vue")
  },
  {
    name: "dashboard-subcomponents-GettingStarted",
    path: "subcomponents/GettingStarted",
    component: () => import("/app/pages/dashboard/subcomponents/GettingStarted.vue")
  },
  {
    name: "dashboard-subcomponents-GettingStartedCard",
    path: "subcomponents/GettingStartedCard",
    component: () => import("/app/pages/dashboard/subcomponents/GettingStartedCard.vue")
  },
  {
    name: "dashboard-subcomponents-ShortcutCard",
    path: "subcomponents/ShortcutCard",
    component: () => import("/app/pages/dashboard/subcomponents/ShortcutCard.vue")
  },
  {
    name: "dashboard-subcomponents-Shortcuts",
    path: "subcomponents/Shortcuts",
    component: () => import("/app/pages/dashboard/subcomponents/Shortcuts.vue")
  }
]
  },
  {
    name: data_45productswATmH8QrEuMeta?.name,
    path: "/data-products",
    meta: data_45productswATmH8QrEuMeta || {},
    component: () => import("/app/pages/data-products.vue"),
    children: [
  {
    name: _91id_933HxNXI5qt6Meta?.name,
    path: ":id()",
    meta: _91id_933HxNXI5qt6Meta || {},
    component: () => import("/app/pages/data-products/[id].vue"),
    children: [
  {
    name: "data-products-id-details-next",
    path: "details-next",
    component: () => import("/app/pages/data-products/[id]/details-next.vue"),
    children: [
  {
    name: "data-products-id-details-next-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/details-next/datasets/[datasetId].vue"),
    children: [
  {
    name: "data-products-id-details-next-datasets-datasetId-frames-frameId",
    path: "frames/:frameId()",
    component: () => import("/app/pages/data-products/[id]/details-next/datasets/[datasetId]/frames/[frameId].vue")
  }
]
  }
]
  },
  {
    name: detailsySbTGOyxcIMeta?.name,
    path: "details",
    component: () => import("/app/pages/data-products/[id]/details.vue"),
    children: [
  {
    name: "data-products-id-details-datasets-datasetId-documentation",
    path: "datasets/:datasetId()/documentation",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/documentation.vue")
  },
  {
    name: "data-products-id-details-datasets-datasetId-frames",
    path: "datasets/:datasetId()/frames",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/frames.vue")
  },
  {
    name: "data-products-id-details-datasets-datasetId-history",
    path: "datasets/:datasetId()/history",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/history.vue")
  },
  {
    name: "data-products-id-details-datasets-datasetId",
    path: "datasets/:datasetId()",
    meta: indexyfZqSZhOKAMeta || {},
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/index.vue")
  },
  {
    name: "data-products-id-details-datasets-datasetId-notification-rule",
    path: "datasets/:datasetId()/notification-rule",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/notification-rule.vue")
  },
  {
    name: "data-products-id-details-datasets-datasetId-results",
    path: "datasets/:datasetId()/results",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/results.vue")
  },
  {
    name: "data-products-id-details-datasets-datasetId-schedule",
    path: "datasets/:datasetId()/schedule",
    component: () => import("/app/pages/data-products/[id]/details/datasets/[datasetId]/schedule.vue")
  },
  {
    name: "data-products-id-details-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/details/datasets/index.vue")
  },
  {
    name: "data-products-id-details-history",
    path: "history",
    component: () => import("/app/pages/data-products/[id]/details/history.vue")
  },
  {
    name: "data-products-id-details",
    path: "",
    meta: index7yTHezPipUMeta || {},
    component: () => import("/app/pages/data-products/[id]/details/index.vue")
  },
  {
    name: "data-products-id-details-results",
    path: "results",
    meta: index0QROvc3LbHMeta || {},
    component: () => import("/app/pages/data-products/[id]/details/results/index.vue")
  },
  {
    name: "data-products-id-details-summary",
    path: "summary",
    component: () => import("/app/pages/data-products/[id]/details/summary.vue")
  }
]
  },
  {
    name: editBLrexcbMJYMeta?.name,
    path: "edit",
    component: () => import("/app/pages/data-products/[id]/edit.vue"),
    children: [
  {
    name: "data-products-id-edit-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/edit/datasets.vue")
  },
  {
    name: "data-products-id-edit-destinations",
    path: "destinations",
    component: () => import("/app/pages/data-products/[id]/edit/destinations.vue")
  },
  {
    name: "data-products-id-edit",
    path: "",
    meta: indexeJBeo05DY6Meta || {},
    component: () => import("/app/pages/data-products/[id]/edit/index.vue")
  },
  {
    name: "data-products-id-edit-schemas",
    path: "schemas",
    component: () => import("/app/pages/data-products/[id]/edit/schemas.vue")
  }
]
  },
  {
    name: "data-products-id",
    path: "",
    meta: indexK1dzq5JKozMeta || {},
    component: () => import("/app/pages/data-products/[id]/index.vue")
  },
  {
    name: managezhSM21OJHzMeta?.name,
    path: "manage",
    meta: managezhSM21OJHzMeta || {},
    component: () => import("/app/pages/data-products/[id]/manage.vue"),
    children: [
  {
    name: "data-products-id-manage",
    path: "",
    meta: indexUkAlVXacdSMeta || {},
    component: () => import("/app/pages/data-products/[id]/manage/index.vue")
  },
  {
    name: "data-products-id-manage-settings",
    path: "settings",
    component: () => import("/app/pages/data-products/[id]/manage/settings.vue")
  },
  {
    name: "data-products-id-manage-subscribers",
    path: "subscribers",
    component: () => import("/app/pages/data-products/[id]/manage/subscribers.vue")
  },
  {
    name: "data-products-id-manage-subscription",
    path: "subscription",
    component: () => import("/app/pages/data-products/[id]/manage/subscription.vue")
  }
]
  },
  {
    name: "data-products-id-scratch",
    path: "scratch",
    meta: scratch06k8wkTd4tMeta || {},
    component: () => import("/app/pages/data-products/[id]/scratch.vue"),
    children: [
  {
    name: "data-products-id-scratch-connection",
    path: "connection",
    component: () => import("/app/pages/data-products/[id]/scratch/connection.vue")
  },
  {
    name: data_45modelingP8fX3XtLSdMeta?.name,
    path: "data-modeling",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling.vue"),
    children: [
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId-frames-frameId",
    path: "datasets/:datasetId()/frames/:frameId()",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/[frameId]/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId-frames",
    path: "datasets/:datasetId()/frames",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/frames/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/[datasetId]/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/datasets/index.vue")
  },
  {
    name: "data-products-id-scratch-data-modeling",
    path: "",
    component: () => import("/app/pages/data-products/[id]/scratch/data-modeling/index.vue")
  }
]
  },
  {
    name: "data-products-id-scratch-destinations",
    path: "destinations",
    component: () => import("/app/pages/data-products/[id]/scratch/destinations.vue")
  },
  {
    name: "data-products-id-scratch-finalize",
    path: "finalize",
    component: () => import("/app/pages/data-products/[id]/scratch/finalize.vue")
  },
  {
    name: "data-products-id-scratch-notification-rules",
    path: "notification-rules",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules.vue"),
    children: [
  {
    name: "data-products-id-scratch-notification-rules-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules/datasets/[datasetId]/index.vue")
  },
  {
    name: "data-products-id-scratch-notification-rules-datasets",
    path: "datasets",
    component: () => import("/app/pages/data-products/[id]/scratch/notification-rules/datasets/index.vue")
  }
]
  },
  {
    name: "data-products-id-scratch-profile",
    path: "profile",
    component: () => import("/app/pages/data-products/[id]/scratch/profile.vue")
  },
  {
    name: "data-products-id-scratch-schedule",
    path: "schedule",
    component: () => import("/app/pages/data-products/[id]/scratch/schedule.vue")
  }
]
  }
]
  },
  {
    name: "data-products",
    path: "",
    component: () => import("/app/pages/data-products/index.vue"),
    children: [
  {
    name: "data-products-index-entitlements",
    path: "entitlements",
    component: () => import("/app/pages/data-products/index/entitlements.vue")
  }
]
  }
]
  },
  {
    name: health_45dashboarduKP3YD6NTIMeta?.name,
    path: "/health-dashboard",
    meta: health_45dashboarduKP3YD6NTIMeta || {},
    component: () => import("/app/pages/health-dashboard.vue"),
    children: [
  {
    name: "health-dashboard",
    path: "",
    component: () => import("/app/pages/health-dashboard/index.vue"),
    children: [
  {
    name: "health-dashboard-index-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/health-dashboard/index/datasets/[datasetId].vue"),
    children: [
  {
    name: "health-dashboard-index-datasets-datasetId-deliveries-deliveryId",
    path: "deliveries/:deliveryId()",
    component: () => import("/app/pages/health-dashboard/index/datasets/[datasetId]/deliveries/[deliveryId]/index.vue")
  }
]
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutHaVXWqg8UAMeta || {},
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: orgsMF7Scv4SIcMeta?.name,
    path: "/orgs",
    meta: orgsMF7Scv4SIcMeta || {},
    component: () => import("/app/pages/orgs.vue"),
    children: [
  {
    name: _91id_934o8ocNME0FMeta?.name,
    path: ":id()",
    meta: _91id_934o8ocNME0FMeta || {},
    component: () => import("/app/pages/orgs/[id].vue"),
    children: [
  {
    name: "orgs-id-data-products",
    path: "data-products",
    component: () => import("/app/pages/orgs/[id]/data-products.vue"),
    children: [
  {
    name: "orgs-id-data-products-dataProductId",
    path: ":dataProductId()",
    meta: _91dataProductId_93FwtbWQ0xqqMeta || {},
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId].vue"),
    children: [
  {
    name: "orgs-id-data-products-dataProductId-settings",
    path: "settings",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/settings.vue")
  },
  {
    name: "orgs-id-data-products-dataProductId-subscribers",
    path: "subscribers",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/subscribers.vue")
  },
  {
    name: "orgs-id-data-products-dataProductId-subscription",
    path: "subscription",
    component: () => import("/app/pages/orgs/[id]/data-products/[dataProductId]/subscription.vue")
  }
]
  }
]
  },
  {
    name: "orgs-id",
    path: "",
    component: () => import("/app/pages/orgs/[id]/index.vue"),
    children: [
  {
    name: "orgs-id-index-entitlements",
    path: "entitlements",
    component: () => import("/app/pages/orgs/[id]/index/entitlements.vue")
  }
]
  },
  {
    name: "orgs-id-plan",
    path: "plan",
    component: () => import("/app/pages/orgs/[id]/plan.vue")
  }
]
  },
  {
    name: create4muSqvBn4VMeta?.name,
    path: "create",
    component: () => import("/app/pages/orgs/create.vue"),
    children: [
  {
    name: "orgs-create-id-users",
    path: ":id()/users",
    component: () => import("/app/pages/orgs/create/[id]/users.vue")
  },
  {
    name: "orgs-create-type-details",
    path: ":type()/details",
    component: () => import("/app/pages/orgs/create/[type]/details.vue")
  },
  {
    name: "orgs-create-type-name",
    path: ":type()/name",
    component: () => import("/app/pages/orgs/create/[type]/name.vue")
  },
  {
    name: "orgs-create-type-plans",
    path: ":type()/plans",
    component: () => import("/app/pages/orgs/create/[type]/plans.vue")
  },
  {
    name: "orgs-create",
    path: "",
    component: () => import("/app/pages/orgs/create/index.vue")
  }
]
  },
  {
    name: "orgs",
    path: "",
    component: () => import("/app/pages/orgs/index.vue")
  },
  {
    name: "orgs-type",
    path: "type",
    component: () => import("/app/pages/orgs/type.vue")
  }
]
  },
  {
    name: "password-action-token-email",
    path: "/password/:action()/:token()/:email()",
    meta: index7lupYtO9UwMeta || {},
    component: () => import("/app/pages/password/[action]/[token]/[email]/index.vue")
  },
  {
    name: "recover-password",
    path: "/recover-password",
    meta: recover_45passwordFzhfEITEoUMeta || {},
    component: () => import("/app/pages/recover-password.vue")
  },
  {
    name: settingsLwEYOlkQxrMeta?.name,
    path: "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    component: () => import("/app/pages/settings.vue"),
    children: [
  {
    name: "settings-api-keys",
    path: "api-keys",
    component: () => import("/app/pages/settings/api-keys.vue")
  },
  {
    name: "settings-general",
    path: "general",
    component: () => import("/app/pages/settings/general.vue")
  },
  {
    name: "settings",
    path: "",
    meta: index11eXGkSJBcMeta || {},
    component: () => import("/app/pages/settings/index.vue")
  },
  {
    name: notifications2EXqO5sHnDMeta?.name,
    path: "notifications",
    meta: notifications2EXqO5sHnDMeta || {},
    component: () => import("/app/pages/settings/notifications.vue"),
    children: [
  {
    name: channels1pdq4LMJIUMeta?.name,
    path: "channels",
    component: () => import("/app/pages/settings/notifications/channels.vue"),
    children: [
  {
    name: "settings-notifications-channels-email",
    path: "email",
    component: () => import("/app/pages/settings/notifications/channels/email.vue")
  },
  {
    name: "settings-notifications-channels",
    path: "",
    meta: indexnl571opJf3Meta || {},
    component: () => import("/app/pages/settings/notifications/channels/index.vue")
  },
  {
    name: "settings-notifications-channels-pub-sub",
    path: "pub-sub",
    component: () => import("/app/pages/settings/notifications/channels/pub-sub.vue")
  },
  {
    name: "settings-notifications-channels-sqs",
    path: "sqs",
    component: () => import("/app/pages/settings/notifications/channels/sqs.vue")
  },
  {
    name: "settings-notifications-channels-webhook",
    path: "webhook",
    component: () => import("/app/pages/settings/notifications/channels/webhook.vue")
  }
]
  },
  {
    name: "settings-notifications",
    path: "",
    meta: index98zCyyEGM5Meta || {},
    component: () => import("/app/pages/settings/notifications/index.vue")
  },
  {
    name: "settings-notifications-preferences",
    path: "preferences",
    component: () => import("/app/pages/settings/notifications/preferences.vue")
  },
  {
    name: "settings-notifications-rules-id-edit",
    path: "rules/:id()/edit",
    component: () => import("/app/pages/settings/notifications/rules/[id]/edit.vue")
  },
  {
    name: "settings-notifications-rules-id",
    path: "rules/:id()",
    component: () => import("/app/pages/settings/notifications/rules/[id]/index.vue")
  },
  {
    name: "settings-notifications-rules-create",
    path: "rules/create",
    component: () => import("/app/pages/settings/notifications/rules/create.vue")
  },
  {
    name: "settings-notifications-rules",
    path: "rules",
    component: () => import("/app/pages/settings/notifications/rules/index.vue")
  }
]
  },
  {
    name: plan_45details1y8jXKABDOMeta?.name,
    path: "plan-details",
    meta: plan_45details1y8jXKABDOMeta || {},
    component: () => import("/app/pages/settings/plan-details.vue"),
    children: [
  {
    name: "settings-plan-details",
    path: "",
    meta: indexZnsbpzMDVSMeta || {},
    component: () => import("/app/pages/settings/plan-details/index.vue")
  },
  {
    name: "settings-plan-details-upgrade",
    path: "upgrade",
    component: () => import("/app/pages/settings/plan-details/upgrade.vue")
  },
  {
    name: "settings-plan-details-your-plan",
    path: "your-plan",
    component: () => import("/app/pages/settings/plan-details/your-plan.vue")
  }
]
  },
  {
    name: usagelSxxSINus2Meta?.name,
    path: "usage",
    meta: usagelSxxSINus2Meta || {},
    component: () => import("/app/pages/settings/usage.vue"),
    children: [
  {
    name: "settings-usage-history",
    path: "history",
    component: () => import("/app/pages/settings/usage/history.vue")
  },
  {
    name: "settings-usage",
    path: "",
    meta: indexlKLobfYM9eMeta || {},
    component: () => import("/app/pages/settings/usage/index.vue")
  }
]
  },
  {
    name: "settings-user-management",
    path: "user-management",
    meta: user_45managementNlGkd4mCWzMeta || {},
    component: () => import("/app/pages/settings/user-management.vue"),
    children: [
  {
    name: "settings-user-management-add-user",
    path: "add-user",
    component: () => import("/app/pages/settings/user-management/add-user.vue")
  }
]
  }
]
  },
  {
    name: "studio",
    path: "/studio",
    meta: studioyhbHREGAZRMeta || {},
    component: () => import("/app/pages/studio.vue"),
    children: [
  {
    name: "studio-data-products",
    path: "data-products",
    component: () => import("/app/pages/studio/data-products.vue"),
    children: [
  {
    name: _91dataProductId_93MZnh1RgD3RMeta?.name,
    path: ":dataProductId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId].vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-connect",
    path: "connect",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-connect-custom",
    path: "custom",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect/custom.vue")
  },
  {
    name: "studio-data-products-dataProductId-connect-existing",
    path: "existing",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect/existing.vue")
  },
  {
    name: "studio-data-products-dataProductId-connect-new",
    path: "new",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/connect/new.vue")
  }
]
  },
  {
    name: "studio-data-products-dataProductId-destinations",
    path: "destinations",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/destinations.vue")
  },
  {
    name: "studio-data-products-dataProductId",
    path: "",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/index.vue")
  },
  {
    name: modelVmGhcA8jHUMeta?.name,
    path: "model",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-model-datasets-datasetId-frames-frameId",
    path: "datasets/:datasetId()/frames/:frameId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/frames/[frameId]/index.vue")
  },
  {
    name: "studio-data-products-dataProductId-model-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model/datasets/[datasetId]/index.vue")
  },
  {
    name: "studio-data-products-dataProductId-model",
    path: "",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/model/index.vue")
  }
]
  },
  {
    name: "studio-data-products-dataProductId-review",
    path: "review",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-review-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId].vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-review-datasets-datasetId-frames-frameId",
    path: "frames/:frameId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/review/datasets/[datasetId]/frames/[frameId].vue")
  }
]
  }
]
  },
  {
    name: "studio-data-products-dataProductId-schedule",
    path: "schedule",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule.vue"),
    children: [
  {
    name: "studio-data-products-dataProductId-schedule-datasets-datasetId",
    path: "datasets/:datasetId()",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule/datasets/[datasetId].vue")
  },
  {
    name: "studio-data-products-dataProductId-schedule-datasets",
    path: "datasets",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/schedule/datasets/index.vue")
  }
]
  },
  {
    name: "studio-data-products-dataProductId-select-data",
    path: "select-data",
    component: () => import("/app/pages/studio/data-products/[dataProductId]/select-data.vue")
  }
]
  },
  {
    name: "studio-data-products-create",
    path: "create",
    meta: createXxlGqWqVaCMeta || {},
    component: () => import("/app/pages/studio/data-products/create.vue")
  }
]
  }
]
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditionszK6eZNsNEXMeta || {},
    component: () => import("/app/pages/terms-and-conditions.vue")
  }
]
import { cloneDeep, findIndex, mergeWith, reject } from 'lodash-es';

import {
  DELETE_WORKFLOW_FRAME,
  MERGE_INFERRED_DATASET_INFO,
  REMEMBER_PROFILING_NOTIFICATION_MODAL,
  RESET_DATA_PRODUCT,
  RESET_DATA_PRODUCTS,
  SET_CONNECTION,
  SET_CONNECTIONS,
  SET_DATA_PRODUCT_DATASETS,
  SET_DATA_PRODUCT_HISTORY,
  SET_DATA_PRODUCT_PROFILING_NOTIFICATION_ACTIVE,
  SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING,
  SET_DATA_PRODUCT,
  SET_DATA_PRODUCT_DESTINATIONS,
  SET_DATA_PRODUCT_ONBOARDING_STATUS,
  SET_DATA_PRODUCTS,
  SET_DESTINATIONS,
  SET_DISMISSED_DEPLOY_ALERTS,
  SET_RESOURCES,
  SET_WORKFLOW_FRAME_NAME,
  UPDATE_CONNECTION,
  UPDATE_DATASTORES_MAP,
} from './mutation-types';

export default {
  [DELETE_WORKFLOW_FRAME](state, { workflow, frame }) {
    const workflows = [...state.dataProduct.workflows];
    const workflowIndex = findIndex(workflows, { id: workflow.id });

    workflows[workflowIndex].frames = reject(workflows[workflowIndex].frames, { id: frame.id });

    state.dataProduct.workflows = workflows;
  },

  [MERGE_INFERRED_DATASET_INFO](state, inferredDataset) {
    const inferredDatasets = [...state.dataProduct.inferredDatasets];
    const inferredDatasetIndex = findIndex(inferredDatasets, { id: inferredDataset.id });

    mergeWith(inferredDatasets[inferredDatasetIndex], inferredDataset);

    state.dataProduct.inferredDatasets = inferredDatasets;
  },

  [REMEMBER_PROFILING_NOTIFICATION_MODAL](state) {
    state.shouldShowProfileNotificationModal = true;
  },

  [RESET_DATA_PRODUCT](state) {
    if (state.dataProduct) {
      state.lastDataProductId = state.dataProduct.id;
    }

    state.connection = null;
    state.connections = [];
    state.dataProduct = null;
    state.dataProductHistory = [];
    state.dataProductDestinations = [];
    state.destinations = [];
    state.datasets = [];
  },

  [RESET_DATA_PRODUCTS](state) {
    state.dataProducts = [];
  },

  [SET_CONNECTION](state, connection) {
    state.connection = connection;
  },

  [SET_CONNECTIONS](state, connections) {
    state.connections = cloneDeep(connections);
  },

  [SET_DATA_PRODUCT](state, dataProduct) {
    state.dataProduct = cloneDeep(dataProduct);
    state.lastDataProductId = state.dataProduct?.id;
  },

  [SET_DATA_PRODUCTS](state, dataProducts) {
    state.dataProducts = cloneDeep(dataProducts);
  },

  [SET_DATA_PRODUCT_DATASETS](state, datasets) {
    state.datasets = cloneDeep(datasets);
  },

  [SET_DATA_PRODUCT_DESTINATIONS](state, destinations) {
    state.dataProductDestinations = cloneDeep(destinations);
  },

  [SET_DATA_PRODUCT_HISTORY](state, history) {
    state.dataProductHistory = cloneDeep(history);
  },

  [SET_DATA_PRODUCT_ONBOARDING_STATUS](state, onboardingStatus) {
    state.dataProduct.onboardingStatus = cloneDeep(onboardingStatus);
  },

  [SET_DATA_PRODUCT_PROFILING_NOTIFICATION_ACTIVE](state, active) {
    state.profilingNotificationActive = active;
  },

  [SET_DATA_PRODUCT_PROFILING_NOTIFICATION_LOADING](state, loading) {
    state.profilingNotificationLoading = loading;
  },

  [SET_DESTINATIONS](state, destinations) {
    state.destinations = cloneDeep(destinations);
  },

  [SET_DISMISSED_DEPLOY_ALERTS](state, dismissedDeployAlerts) {
    state.dismissedDeployAlerts = cloneDeep(dismissedDeployAlerts);
  },

  [SET_RESOURCES](state, resources) {
    state.resources = resources;
  },

  [SET_WORKFLOW_FRAME_NAME](state, { workflow, frame, newName }) {
    const workflows = [...state.dataProduct.workflows];
    const workflowIndex = findIndex(workflows, { id: workflow.id });
    const frameIndex = findIndex(workflows[workflowIndex].frames, {
      id: frame.id,
    });

    workflows[workflowIndex].frames[frameIndex].name = newName;

    state.dataProduct.workflows = workflows;
  },

  [UPDATE_CONNECTION](state, connection) {
    const connections = [...state.connections];
    const connectionIndex = findIndex(connections, { id: connection.id });

    connections[connectionIndex] = cloneDeep(connection);

    state.connections = connections;
  },

  [UPDATE_DATASTORES_MAP](state, { datastoreId, frames }) {
    state.dataProduct.datastoresMap[String(datastoreId)] = frames;
  },
};

<template>
  <headless-tag-input
    v-slot="{ events, remove, state, tags }"
    v-model="localValues"
    :keep-on-backspace="keepOnBackspace"
    :disabled="disabled"
    @update:model-value="syncLocalValues"
  >
    <div
      class="bg-white border flex flex-wrap gap-2 items-center px-4 py-2 rounded-md"
      :class="[validityClasses(invalid)]"
    >
      <cx-badge
        v-for="(tag, index) in tags"
        :id="cxTagInputId(index)"
        :key="index"
        :class="[tagValidityClasses(invalid, tag)]"
        color="darkGray"
        :size="tagSize"
      >
        <span class="flex items-center space-x-2">
          <span :id="cxTagInputLabelId(index)" class="break-all"> {{ tag }} </span>
          <button
            type="button"
            :aria-controls="cxTagInputId(index)"
            :aria-describedby="cxTagInputLabelId(index)"
            aria-keyshortcuts="Delete"
            :aria-label="$t('removeTagAriaLabel', { tag })"
            class="h-full inline-flex items-center text-gray-500"
            @keydown.delete="remove(tag)"
            @click="remove(tag)"
          >
            <cx-icon name="close-fill" size="lg" />
          </button>
        </span>
      </cx-badge>
      <input
        v-bind="state"
        class="flex-1 focus:outline-none h-6 text-sm"
        :placeholder="modifiedPlaceholder"
        v-on="events"
      />
    </div>
  </headless-tag-input>
</template>

<script>
import { CxBadge, CxIcon, LG } from '~/components/cx';

import HeadlessTagInput from './HeadlessTagInput.vue';

export default {
  name: 'CxTagInput',

  components: {
    CxBadge,
    CxIcon,
    HeadlessTagInput,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    /* c8 ignore start */
    invalidTags: {
      type: Array,
      default: () => [],
    },
    /* c8 ignore stop */

    keepOnBackspace: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: Array,
      required: true,
    },

    placeholder: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      localValues: [],
    };
  },

  computed: {
    modifiedPlaceholder() {
      return ` + ${this.placeholder}`;
    },
  },

  created() {
    this.tagSize = LG;
  },

  mounted() {
    this.setLocalValues();
  },

  methods: {
    cxTagInputId(index) {
      return `cx-tag-input-${index}`;
    },

    cxTagInputLabelId(index) {
      return `cx-tag-input-${index}-label`;
    },

    setLocalValues() {
      this.localValues = [...this.modelValue];
    },

    syncLocalValues(localValues) {
      this.$emit('update:modelValue', localValues);
    },

    tagValidityClasses(invalid, tag) {
      return invalid && this.invalidTags.includes(tag) ? 'border border-red-500 rounded' : '';
    },

    validityClasses(invalid) {
      return invalid
        ? 'bg-red-50 border-red-300 text-red-900 placeholder-red-300 focus-within:ring-red-500 focus-within:border-red-500'
        : 'border-gray-200 focus-within:border-blue-600';
    },
  },
};
</script>

<i18n lang="json">
{
  "en": {
    "removeTagAriaLabel": "remove tag {tag}"
  }
}
</i18n>

import { alpha_num as alphaNum, email, max, min, not_one_of as notOneOf, required } from '@vee-validate/rules';
import { defineRule } from 'vee-validate';

import {
  alphaWithUnderscores,
  ascii,
  cron,
  cronEndTime,
  cronInterval,
  dateRangeStartRequired,
  emailDomains,
  equals,
  firstCharacterNotNumber,
  fullName,
  host,
  hostOrIp,
  ip,
  oneChildFieldRequired,
  oneFieldRequired,
  orgCode,
  password,
  phone,
  port,
  url,
  VALIDATION_MESSAGES_MAP,
} from '~/support/validation';

export default defineNuxtPlugin(() => {
  defineRule('alphaWithUnderscores', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!alphaWithUnderscores(value)) return VALIDATION_MESSAGES_MAP.alphaWithUnderscores;

    return true;
  });

  defineRule('alphaNum', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!alphaNum(value)) return VALIDATION_MESSAGES_MAP.alphaNum;

    return true;
  });

  defineRule('ascii', (value, { max, errorText }) => {
    if (!value || !value.length) {
      return true;
    }

    if (!ascii(value, { max })) return errorText;

    return true;
  });

  defineRule('cron', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!cron(value)) return false;

    return true;
  });

  defineRule('cronEndTime', (value, params) => {
    if (typeof value !== 'number') {
      return true;
    }

    if (!cronEndTime(value, params)) return VALIDATION_MESSAGES_MAP.cronEndTime;

    return true;
  });

  defineRule('cronInterval', (value, params) => {
    if (typeof value !== 'number') {
      return true;
    }

    if (!cronInterval(value, params)) return VALIDATION_MESSAGES_MAP.cronInterval;

    return true;
  });

  defineRule('dateRangeStartRequired', (value) => {
    if (!dateRangeStartRequired(value)) return VALIDATION_MESSAGES_MAP.dateRangeStartRequired;

    return true;
  });

  defineRule('email', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!email(value)) return VALIDATION_MESSAGES_MAP.email;

    return true;
  });

  defineRule('equals', (value, params) => {
    if (!equals(value, params)) return `${VALIDATION_MESSAGES_MAP.equals} ${params.to}`;

    return true;
  });

  defineRule('firstCharacterNotNumber', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!firstCharacterNotNumber(value)) return VALIDATION_MESSAGES_MAP.firstCharacterNotNumber;

    return true;
  });

  defineRule('fullName', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!fullName(value)) return VALIDATION_MESSAGES_MAP.fullName;

    return true;
  });

  defineRule('oneChildFieldRequired', (value, params) => {
    if (!value || !value.length) {
      return true;
    }

    if (!oneChildFieldRequired(value, params)) return VALIDATION_MESSAGES_MAP.oneChildFieldRequired;

    return true;
  });

  defineRule('oneFieldRequired', (_, { values, message = VALIDATION_MESSAGES_MAP.oneFieldRequired }) => {
    if (!oneFieldRequired(values)) return message;

    return true;
  });

  defineRule('orgCode', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!orgCode(value)) return VALIDATION_MESSAGES_MAP.orgCode;

    return true;
  });

  defineRule('host', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!host(value)) return false;

    return true;
  });

  defineRule('hostOrIp', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!hostOrIp(value)) return false;

    return true;
  });

  defineRule('ip', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!ip(value)) return false;

    return true;
  });

  defineRule('max', (value, params) => {
    if (!value || !value.length) {
      return true;
    }

    if (!max(value, params)) return VALIDATION_MESSAGES_MAP.max;

    return true;
  });

  defineRule('min', (value, params) => {
    if (!value || !value.length) {
      return true;
    }

    if (!min(value, params)) return VALIDATION_MESSAGES_MAP.min;

    return true;
  });

  defineRule('password', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!password(value)) return VALIDATION_MESSAGES_MAP.password;

    return true;
  });

  defineRule('confirmPassword', (value, { password }) => {
    if (value !== password) {
      return VALIDATION_MESSAGES_MAP.confirmPassword;
    }

    return true;
  });

  defineRule('notOneOf', (value, values) => {
    if (!notOneOf(value, values)) return VALIDATION_MESSAGES_MAP.notOneOf;

    return true;
  });

  defineRule('phone', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!phone(value)) return VALIDATION_MESSAGES_MAP.phone;

    return true;
  });

  defineRule('port', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!port(value)) return false;

    return true;
  });

  defineRule('required', (value) => {
    if (!required(value)) return VALIDATION_MESSAGES_MAP.required;

    return true;
  });

  defineRule('url', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!url(value)) return VALIDATION_MESSAGES_MAP.url;

    return true;
  });

  defineRule('emailDomains', (value) => {
    if (!value || !value.length) {
      return true;
    }

    if (!emailDomains(value)) return VALIDATION_MESSAGES_MAP.emailDomains;

    return true;
  });
});

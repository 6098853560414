import { HealthService } from '~/support/services';

export default {
  async fetchDataset(_, id) {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDataset(id);

    return data;
  },

  async fetchDatasetDelivery(_, { datasetId, deliveryId }) {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDatasetDelivery(datasetId, deliveryId);

    return data;
  },

  async fetchDatasetDeliveryV2(_, deliveryId) {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDatasetDeliveryV2(deliveryId);

    return data;
  },

  async fetchDatasetLatestDelivery(_, id) {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDatasetLatestDelivery(id);

    return data;
  },

  async fetchDatasetV2(_, id) {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDatasetV2(id);

    return data;
  },

  async fetchDatasets() {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDatasets();

    return data;
  },

  async fetchDatasetsCount() {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDatasetsCount();

    return data;
  },

  async fetchDatasetsV2(_, { filters, pageToken, query, sortSettings } = {}) {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDatasetsV2({ filters, pageToken, query, sortSettings });

    return data;
  },

  async fetchDeliveryTaskHistory(_, { deliveryId }) {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchDeliveryTaskHistory(deliveryId);

    return data;
  },

  async fetchStatistics() {
    const service = new HealthService(useRuntimeConfig().public.healthBaseUrl);
    const data = await service.fetchStatistics();

    return data;
  },
};

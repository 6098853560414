import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/app/middleware/auth.js"),
  catalog: () => import("/app/middleware/catalog.js"),
  guest: () => import("/app/middleware/guest.js"),
  password: () => import("/app/middleware/password.js")
}
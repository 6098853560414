export const NAV_ICONS = {
  catalog: 'book-2',
  connectionSettings: 'plug',
  dataProducts: 'server',
  healthDashboard: 'heart-pulse',
  organizations: 'building-4',
  studio: 'add-line',
};

export const NAV_ROUTES = Object.freeze({
  catalog: { name: 'catalog' }, // go to families until we have a design for the catalog landing page
  connectionSettings: { name: 'connection-settings' },
  dashboard: { name: 'dashboard' },
  dataProducts: { name: 'data-products' },
  discoverSuppliers: { name: 'discover-suppliers' },
  healthDashboard: { name: 'health-dashboard' },
  organizations: { name: 'orgs' },
  settings: { name: 'settings' },
  studio: { name: 'studio-data-products-create' },
});
